const server = {
    path: "/main",
    name: "main",
    component: () => import("../views/house.vue"),
    redirect: "/console/main",
    meta: { title: "menuMain", },
    children: [
        {
            path: '/console/main',
            name: 'consoleMain',
            component: () => import('../views/house/main.vue'),
            meta: { title: "submenuMain" },
        },

    ]
};

export default server;
