const server = {
    path: "/shop",
    name: "shop",
    component: () => import("../views/house.vue"),
    redirect: "/console/shop",
    meta: { title: "menuShop", },
    children: [
        {
            path: '/console/shop',
            name: 'consoleShop',
            component: () => import('../views/house/shop.vue'),
            meta: { title: "submenuShop" },
        },

    ]
};

export default server;
