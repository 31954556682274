const server = {
    path: "/market",
    name: "market",
    component: () => import("../views/house.vue"),
    redirect: "/console/market",
    meta: { title: "menuMarket", },
    children: [
        {
            path: '/console/market',
            name: 'consoleMarket',
            component: () => import('../views/house/market.vue'),
            meta: { title: "submenuMarket" },
        },

    ]
};

export default server;
