const server = {
    path: "/apiServer",
    name: "apiServer",
    component: () => import("../views/house.vue"),
    redirect: "/console/apiServer",
    meta: { title: "menuApiServer", },
    children: [
        {
            path: '/console/apiServer',
            name: 'consoleApiServer',
            component: () => import('../views/house/apiServer.vue'),
            meta: { title: "submenuApiServer" },
        },
        {
            path: '/console/chatting',
            name: 'consoleChatting',
            component: () => import('../views/house/chatting.vue'),
            meta: { title: "submenuChatting" },
        },

    ]
};

export default server;
