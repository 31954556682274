export default {
    outimgList: "/v1/outimg/list", // 测试接口  （需要登录）

    // 登录页面----------------------------------------
    getInfo: "user/info", // 用户信息
    getsmscode: "user/get_smscode", // 发送短信验证码
    login: "user/login", // 账号密码登录
    loginsms: "user/login_sms", // 短信验证码登录
    userLoginout: "user/logout", // 退出
    userLoginQr: "user/login_qr", // 登录微信公众号二维码
    userLoginScan: "user/login_scan", // 登录微信验证

    // 账号管理页面-------------------------------------
    userSetPassword: "user/set_password", // 设置登录密码
    identityQrcode: "identity/qrcode", // 实名认证(生成二维码)
    identityQuery: "identity/query", // 实名认证(查询认证结果)
    identityCompanyCertify: "identity/company_certify", // 公司认证提交认证资料
    identityCompanyInfo: "identity/company_info", // 公司认证获取认证资料

    // nav导航条页面-------------------------------------
    noticeList: "notice/list", // 公告列表

    // gho页面----------------------------------------
    podLabels: "pod/labels", // label列表
    // pod页面----------------------------------------
    podInstances: "pod/instances", // pod实例列表
    createInstance: "instance/create", // 创建实例
    instanceList: "instance/list", // 实例列表
    instanceContainerInfo: "instance/container_info", // 实例容器信息
    instanceStartup: "instance/startup", // 启动实例
    instanceShutdown: "instance/shutdown", // 关闭实例
    instanceDestroy: "instance/destroy", // 释放实例
    instanceRenewal: "instance/renewal", // 实例续费
    instanceChange2usage: "instance/change2usage", // 转按量计费
    instanceChange2sub: "instance/change2sub", // 转包年包月计费
    instanceShutdownRegular: "instance/shutdown_regular", // 定时关闭实例
    instanceSetAction: "instance/set_action", // 设置实例标题
    instanceStatus: "instance/status", // 实例状态

    gpuModels: "gpu/models", // 显卡型号
    instanceHidden: "instance/hidden", // 隐藏一个实例
    instanceStartupAbort: "instance/startup_abort", // 启动实例(取消)

    podStaticList: "pod/static/list", // Pod静态文件列表
    podStaticUpload: "pod/static/upload", // Pod静态文件上传
    podStaticDel: "pod/static/del", // Pod静态文件删除
    podStaticMarkdown: "pod/static/markdown", // Pod设置Markdown文本
    podAccess: "pod/access", // Pod访问计数
    podFavorite: "pod/favorite", // Pod收藏

    instanceRestart: "instance/restart", // 重启实例
    instanceSaveImage: "instance/save_image", // 保存镜像
    podImageList: "pod_image/list", // 个人镜像
    podImageStatus: "pod_image/status", // 个人镜像状态
    podImageDel: "pod_image/del", // 删除个人镜像
    podImageSquash: "pod_image/squash", // 执行、取消镜像层合并
    podImageResume: "pod_image/resume", // 恢复个人镜像(保存失败的时候)
    tasklogList: "tasklog/list", // 任务日志列表
    instanceSaveImageAbort: "instance/save_image_abort", // 保存镜像取消
    podImageSetTitle: "pod_image/set_title", // 设置个人镜像标题
    podImageSetShare: "pod_image/set_share", // 设置个人镜像分享
    instanceCheck: "instance/check", // 实例Check
    // ai学堂页面----------------------------------------
    apiClassRoomList: "api/class_room/list", // AI课堂列表
    kolClassRoomList: "kol/class_room/list", // AI课堂列表
    classRoomList: "class_room/list", // AI课堂列表
    classRoomApply: "kol/class_room/apply", // AI课堂申请（新增/修改）
    classRoomBindStu: "kol/class_room/bind_stu", // AI课堂添加学员
    classRoomListStu: "kol/class_room/list_stu", // AI课堂学员列表
    classRoomRemoveStu: "kol/class_room/remove_stu", // AI课堂移除学员
    classRoomListPod: "kol/class_room/list_pod", // AI课堂Pod列表
    classRoomSetStu: "kol/class_room/set_stu", // AI课堂设置学员信息
    classRoomSet: "kol/class_room/set", // AI课堂设置课堂信息
    classRoomAuditing: "kol/class_room/auditing", // AI课堂提交审核申请
    institList: "instit/list", // 机构列表
    teacherList: "teacher/list", // 讲师列表
    courseList: "course/list", // 课程列表

    // framer页面----------------------------------------
    kolPodList: "kol/pod/list", // Pod列表
    kolImageAuditing: "kol/pod/auditing", // Pod提交审核申请
    kolPodApply: "kol/pod/apply", // Pod申请（新增/修改）
    kolInstancelCreate: "kol/instance/create", // Pod创建并启动安装实例
    kolImageList: "kol/image/list", // Image列表
    kolImageSelect: "kol/image/select", // Image选择列表
    kolImageCommit: "kol/image/commit", // Image提交镜像
    kolImageCommitAbort: "kol/image/commit_abort", // Image镜像提交强制取消
    kolImageOnsale: "kol/image/onsale", // Image上架镜像
    kolImageOffsale: "kol/image/offsale", // Image下架镜像
    kolImageDel: "kol/image/del", // Image删除镜像
    kolPodOnsale: "kol/pod/onsale", // Pod上架
    kolPodOffsale: "kol/pod/offsale", // Pod下架
    kolPodDel: "kol/pod/del", // Pod删除
    kolInstanceLogsDocker: "kol/instance/logs_docker", // 实例日志
    kolImageCommitLogs: "kol/image/commit_logs", // Image镜像提交日志
    kolImageCommitLocal: "kol/image/commit_local", // Image提交镜像到本地
    kolPodCatalogues: "kol/pod/catalogues", // Pod容器文件夹

    // gpu页面----------------------------------------
    gpuInstances: "pod/gpu_instances", // Gpu实例列表
    // apikeys页面----------------------------------------
    tokenList: "token/list", // token列表
    tokenAdd: "token/add", // 新增token
    tokenModify: "token/modify", // 修改token
    tokenDel: "token/del", // 删除token
    // 计费页面----------------------------------------
    rechargeBalance: "recharge/balance", // 充值记录
    amountBalance: "amount/balance", // 消费流水
    rechargeLaunch: "recharge/launch", // 发起充值 
    rechargeQuery: "recharge/query", // 查询支付状态 
    cardValidCount: "card/valid_count", // 有效卡数量
    invitationStats: "invitation/stats", // 邀请统计
    invitationSet: "invitation/set", // 当月邀请奖励生成
    invitationInvitees: "invitation/invitees", // 被邀请人列表
    withdrawRewards: "withdraw/rewards", // 佣金记录
    withdrawAccounts: "withdraw/accounts", // 提现账户列表
    withdrawApplys: "withdraw/applys", // 提现申请记录
    withdrawBalance: "withdraw/balance", // 佣金流水
    withdrawAddAccount: "withdraw/add_account", // 添加提现账户
    withdrawApply: "withdraw/apply", // 提现申请
    withdrawSet: "withdraw/set", // 验证账户
    // 账号管理页面----------------------------------------
    userSetInfo: "user/set_info", // 查询支付状态 
    identitySendEduEmail: "identity/send_edu_email", // 学生认证发送邮件 
    identityVerifyEduEmail: "identity/verify_edu_email", // 学生认证验证邮件 
    // 文件管理页面----------------------------------------
    files: "files", // 文件列表
    apiFiles: "files", // 分片上传文件
    // 我的算力卡----------------------------------------
    rechargeLaunchBuy: "recharge/launch_buy", // 购买卡(支付购买)
    cardList: "card/list", // 卡列表
    cardBind: "card/bind", // 绑定卡
    couponList: "coupon/list", // 优惠卷查询
    // 算力市场----------------------------------------
    dockerfileComponents: "v1/ccm/dockerfile/cmps", // 基础镜像组件信息
    dockerfileList: "v1/ccm/dockerfile/list", // 已生成基础镜像列表
    dockerfileSave: "v1/ccm/dockerfile/save", // 异步生成基础镜像
    dockerfile: "v1/ccm/dockerfile/get", // 获取已生成基础镜像
    // 训练专区----------------------------------------
    trainSave: "v1/train/save", // 保存训练
    trainFiles: "train_files", // 训练文件
    trainFileDown: "train_filedown", // 下载训练文件
    trainRun: "v1/train/run", // 执行训练动作
    trainGet: "v1/train/get", // 获取训练
    trainList: "v1/train/list", // 训练列表
    trainIdle: "v1/train/idle", // 训练器空闲信息

}