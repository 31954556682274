import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// 引入ant-design-vue;
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import { message } from 'ant-design-vue';

// axios请求
import manageUrl from './http/manage.js'
import { fetchPost, fetchGet } from './http/httpRequest.js'
app.config.globalProperties.$manageUrl = manageUrl
app.config.globalProperties.$http = {
  POST: fetchPost,
  GET: fetchGet,
}

// pinia
import { createPinia } from 'pinia' // ① 引入createPinia方法从pinia
import { createPersistedState } from 'pinia-persistedstate-plugin' // 数据持久化插件
const pinia = createPinia(); // ② 拿到pinia实例
pinia.use(createPersistedState())
app.use(pinia) // 先开小灶注册，后面着急用
// pinia 取值与赋值
import { useHomeStore } from "./store";
const homeStore = useHomeStore();

// mixin 
import commonMixin from "./utils/common.js"

// 路由 
import data from "./router"
let router = data.router
let asyncRoutes = data.asyncRoutes
// 动态添加可访问路由表
asyncRoutes.forEach((item: any) => {
  router.addRoute(item)
})

// 全局路由守卫
// 修订导航栏的页面类型
let modifyPageType = ((path: any) => {
  switch (path) {
    case '/server':
      homeStore.changeHomeMenuTypeState(1);
      break;
    case '/college':
      homeStore.changeHomeMenuTypeState(2);
      break;
    case '/school':
      homeStore.changeHomeMenuTypeState(3);
      break;
    case '/model':
      homeStore.changeHomeMenuTypeState(4);
      break;
    // 新的-------------------------------
    case '/main':
      homeStore.changeHomeMenuTypeState(0);
      break;
    case '/shop':
      homeStore.changeHomeMenuTypeState(1);
      break;
    case '/market':
      homeStore.changeHomeMenuTypeState(2);
      break;
    case '/institute':
      homeStore.changeHomeMenuTypeState(3);
      break;
    case '/apiServer':
      homeStore.changeHomeMenuTypeState(4);
      break;
    case '/command':
      homeStore.changeHomeMenuTypeState(5);
      break;
    case '/drill':
      homeStore.changeHomeMenuTypeState(6);
      break;
    default:
      homeStore.changeHomeMenuTypeState(1);
  }
})
router.beforeEach((to, from, next) => {
  // 有token
  if (homeStore.token && homeStore.user) {
    // 去登录页则直接去首页 以及禁止去的页面也直接去首页
    let USER = homeStore.user ? homeStore.user : { user_type: -1 }
    if (
      to.path === "/console/login" ||
      (to.path === "/console/instituteManage" && USER?.user_type != 4) ||
      (to.path === "/console/creatorManage" && USER?.user_type != 4)
    ) {
      next({ path: "/" });
    } else {
      // console.log(to.path);
      // console.log(router.hasRoute(to.name!))

      // 如果已经注册过此路由
      if (router.hasRoute(to.name!)) {
        // 修订导航栏的页面类型
        // matched就是此路由在路由表中的嵌套族谱
        modifyPageType(to.matched[0].path)

        next();
      } else {
        // 以下配置暂时废弃，他是为了配合/console/pod/${record.pod.uuid}这种路由而写，现在不要这种路由了
        // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        // 如果没有注册过，也就是404状态，则单独处理，因为为了配合uuid页面，router配置里的404选项注释掉了
        // 如果是前往随机uuid页面，就注册/RAM
        // if (to.path.split('/')[2] == 'pod' && to.path.split('/')[3] && to.path.split('/')[3].length > 10) {
        //   router.addRoute({
        //     path: "/RAM",
        //     name: "RAM",
        //     component: () => import("./views/home.vue"),
        //     children: [
        //       {
        //         path: to.path,
        //         name: to.path,
        //         component: () => import("./views/server/ghoDetails.vue"),
        //       },
        //     ],
        //   });

        //   modifyPageType('/server')
        // }
        // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
        // 注册404
        next(`/`);
        return
        router.addRoute({ path: '/:catchAll(.*)', name: 'catchAll', redirect: '/404', });

        // console.log(router.getRoutes());

        // 添加路由并不会触发新的导航。也就是说，除非触发新的导航，否则不会显示所添加的路由’，
        // 所以此时再进行跳转，会出现空白页或404的情况
        // 所以需要重新加载路由
        router.push(to.path);
      }
    }
    // 没token
  } else {
    if (
      to.path === "/" ||
      to.path === "/console/main" ||
      to.path === "/console/shop" ||
      to.path === "/console/newShare" ||
      to.path === "/console/login"
    ) {
      next();
    } else {
      if (to.matched[0]) {
        modifyPageType(to.matched[0].path)
      }
      // message.warning("请登录");
      // 不去登录页也必须去登录页
      next(`/console/main?redirect=${to.fullPath}`);
      // 开启弹框登录
      homeStore.changeLoginPopState(true)
    }

    // 去shop页需要重置一下，因为没有走else
    // 而没有走else的其他四个，都不需要修改页面枚举
    if (to.path === "/console/shop" || (to.path === "/console/main" && !to.query.redirect)) {
      modifyPageType(to.matched[0].path)
    }
  }
});
router.onError((error) => {
  let pattern = /Loading chunk (.*) failed\./;
  if (pattern.test(error.message)) {
    window.location.reload();
  }
});

// 国际化 通过插件的形式挂载
import i18n from './language/index'

// 显示md文件需要---------------
// 主题
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// 显示插件
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
// 编辑插件
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';

// 注册显示/编辑插件
// highlightjs
import hljs from 'highlight.js/lib/core';
VMdPreview.use(vuepressTheme, { Hljs: hljs, });
VMdEditor.use(vuepressTheme, {});
// -------------------------------

// 无限滚动
import infiniteScroll from 'vue3-infinite-scroll-better'

app.use(router)
  .use(infiniteScroll)
  .use(Antd)
  .use(commonMixin)
  .use(i18n)
  .use(VMdPreview)
  .use(VMdEditor)
  .mount('#app')
