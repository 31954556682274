const server = {
    path: "/command",
    name: "command",
    component: () => import("../views/house.vue"),
    redirect: "/console/podExample",
    meta: { title: "menuHouseHead", },
    children: [
        {
            path: '/console/control',
            name: 'control',
            component: () => import('../views/house/control.vue'),
            redirect: "/console/podExample",
            meta: { title: "menuHouseControl" },
            children: [
                {
                    path: '/console/podExample',
                    name: 'podExample',
                    component: () => import('../views/house/podExample.vue'),
                    meta: { title: "menuHouseControlPodExample" },
                },
                {
                    path: '/console/podMirror',
                    name: 'podMirror',
                    component: () => import('../views/house/podMirror.vue'),
                    meta: { title: "menuHouseControlPodMirror" },
                },
                {
                    path: '/console/creatorManage',
                    name: 'creatorManage',
                    component: () => import('../views/house/creatorManage.vue'),
                    meta: { title: "menuHouseControlCreatorManage" },
                },
                {
                    path: '/console/creatorDetails',
                    name: 'creatorDetails',
                    component: () => import('../views/house/creatorDetails.vue'),
                    meta: { title: "menuHouseControlCreatorDetails" },
                },
                {
                    path: '/console/creatorSave',
                    name: 'creatorSave',
                    component: () => import('../views/house/creatorSave.vue'),
                    meta: { title: "menuHouseControlCreatorSave" },
                },
                {
                    path: '/console/accountDetails',
                    name: 'accountDetails',
                    component: () => import('../views/house/accountDetails.vue'),
                    meta: { title: "menuHouseControlAccountDetails" },
                },
                {
                    path: '/console/accountManage',
                    name: 'accountManage',
                    component: () => import('../views/house/accountManage.vue'),
                    meta: { title: "menuHouseControlAccountManage" },
                },
                {
                    path: '/console/fileManage',
                    name: 'fileManage',
                    component: () => import('../views/house/fileManage.vue'),
                    meta: { title: "menuHouseControlFileManage" },
                },
                {
                    path: '/console/connectUs',
                    name: 'connectUs',
                    component: () => import('../views/house/connectUs.vue'),
                    meta: { title: "menuHouseControlConnectUs" },
                },
                {
                    path: '/console/mirrorDetails',
                    name: 'mirrorDetails',
                    component: () => import('../views/house/mirrorDetails.vue'),
                    meta: { title: "menuHouseControlMirrorDetails" },
                },

            ]
        },

    ]
};

export default server;
