const server = {
    path: "/drill",
    name: "drill",
    component: () => import("../views/house.vue"),
    redirect: "/console/training",
    meta: { title: "menuDrill", },
    children: [
        {
            path: '/console/training',
            name: 'consoleTraining',
            component: () => import('../views/drill/training.vue'),
            meta: { title: "submenuTraining" },
        },
        {
            path: '/console/trainingTask',
            name: 'consoleTrainingTask',
            component: () => import('../views/drill/trainingTask.vue'),
            meta: { title: "submenuTrainingTask" },
        },

    ]
};

export default server;
