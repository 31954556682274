import { RouteRecordRaw, createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/console/main',
  },
  {
    path: '/console/login',
    name: 'login',
    component: () => import('../components/login.vue')
  },
  {
    path: '/console/paygress',
    name: 'paygress',
    component: () => import('../components/paygress.vue')
  },
  {
    path: '/console/buycardgress',
    name: 'buycardgress',
    component: () => import('../components/buycardgress.vue')
  },
  // {
  //   path: '/console/share',
  //   name: 'share',
  //   component: () => import('../components/share.vue')
  // },
  {
    path: '/console/newShare',
    name: 'newShare',
    component: () => import('../components/newShare.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../components/404.vue')
  },
]

// -----------------------
// 动态新增路由
// import server from './server'
// import school from './school'
// import college from './college'
// import model from './model'
// import manage from './manage'
// import help from './help'
// import outsource from './outsource'
import main from './main' // 首页
import command from './command' // 控制台
import shop from './shop' // 应用商店
import market from './market' // 算力市场
import institute from './institute' // 晨羽Ai学院
import apiServer from './apiServer' // 大模型API服务
import drill from './drill' // 训练LoRA
const asyncRoutes = [
  // server,
  // school,
  // college,
  // model,
  // manage,
  // help,
  // outsource,
  main,
  command,
  shop,
  market,
  institute,
  apiServer,
  drill,
  // 404 page must be placed at the end !!!
  // { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
]

const router = {
  router: createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  }),
  asyncRoutes: asyncRoutes
}

export default router
